define("discourse/plugins/chat/discourse/components/chat-mention-warnings", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "discourse/lib/get-url", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template, _getUrl, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatMentionWarnings extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatComposerWarningsTracker", [_service.service]))();
    #chatComposerWarningsTracker = (() => (dt7948.i(this, "chatComposerWarningsTracker"), void 0))();
    get unreachableGroupMentions() {
      return this.chatComposerWarningsTracker.unreachableGroupMentions;
    }
    get overMembersLimitGroupMentions() {
      return this.chatComposerWarningsTracker.overMembersLimitGroupMentions;
    }
    get hasTooManyMentions() {
      return this.chatComposerWarningsTracker.tooManyMentions;
    }
    get channelWideMentionDisallowed() {
      return this.chatComposerWarningsTracker.channelWideMentionDisallowed;
    }
    get mentionsCount() {
      return this.chatComposerWarningsTracker.mentionsCount;
    }
    get unreachableGroupMentionsCount() {
      return this.unreachableGroupMentions.length;
    }
    get overMembersLimitMentionsCount() {
      return this.overMembersLimitGroupMentions.length;
    }
    get hasUnreachableGroupMentions() {
      return this.unreachableGroupMentionsCount > 0;
    }
    get hasOverMembersLimitGroupMentions() {
      return this.overMembersLimitMentionsCount > 0;
    }
    get warningsCount() {
      return this.unreachableGroupMentionsCount + this.overMembersLimitMentionsCount;
    }
    get show() {
      return this.hasTooManyMentions || this.channelWideMentionDisallowed || this.hasUnreachableGroupMentions || this.hasOverMembersLimitGroupMentions;
    }
    get listStyleClass() {
      if (this.hasTooManyMentions) {
        return "chat-mention-warnings-list__simple";
      }
      if (this.warningsCount > 1) {
        return "chat-mention-warnings-list__multiple";
      } else {
        return "chat-mention-warnings-list__simple";
      }
    }
    get warningHeaderText() {
      if (this.mentionsCount <= this.warningsCount || this.hasTooManyMentions) {
        return (0, _discourseI18n.i18n)("chat.mention_warning.groups.header.all");
      } else {
        return (0, _discourseI18n.i18n)("chat.mention_warning.groups.header.some");
      }
    }
    get tooManyMentionsBody() {
      if (!this.hasTooManyMentions) {
        return;
      }
      if (this.currentUser.admin) {
        return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("chat.mention_warning.too_many_mentions_admin", {
          count: this.siteSettings.max_mentions_per_chat_message,
          siteSettingUrl: (0, _getUrl.default)("/admin/site_settings/category/plugins?filter=max_mentions_per_chat_message")
        }));
      } else {
        return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("chat.mention_warning.too_many_mentions", {
          count: this.siteSettings.max_mentions_per_chat_message
        }));
      }
    }
    get unreachableBody() {
      if (!this.hasUnreachableGroupMentions) {
        return;
      }
      switch (this.unreachableGroupMentionsCount) {
        case 1:
          return (0, _discourseI18n.i18n)("chat.mention_warning.groups.unreachable_1", {
            group: this.unreachableGroupMentions[0]
          });
        case 2:
          return (0, _discourseI18n.i18n)("chat.mention_warning.groups.unreachable_2", {
            group1: this.unreachableGroupMentions[0],
            group2: this.unreachableGroupMentions[1]
          });
        default:
          return (0, _discourseI18n.i18n)("chat.mention_warning.groups.unreachable_multiple", {
            group: this.unreachableGroupMentions[0],
            count: this.unreachableGroupMentionsCount - 1
          });
      }
    }
    get overMembersLimitBody() {
      if (!this.hasOverMembersLimitGroupMentions) {
        return;
      }
      return (0, _template.htmlSafe)(_discourseI18n.default.messageFormat("chat.mention_warning.groups.too_many_members_MF", {
        groupCount: this.overMembersLimitMentionsCount,
        isAdmin: this.currentUser.admin,
        siteSettingUrl: (0, _getUrl.default)("/admin/site_settings/category/plugins?filter=max_users_notified_per_group_mention"),
        notificationLimit: this.siteSettings.max_users_notified_per_group_mention,
        group1: this.overMembersLimitGroupMentions[0],
        group2: this.overMembersLimitGroupMentions[1]
      }));
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.show}}
          <div class="chat-mention-warnings">
            <div class="chat-mention-warning__icon">
              {{dIcon "triangle-exclamation"}}
            </div>
            <div class="chat-mention-warning__text">
              <div class="chat-mention-warning__header">
                {{this.warningHeaderText}}
              </div>
              <ul class={{this.listStyleClass}}>
                {{#if this.hasTooManyMentions}}
                  <li>{{this.tooManyMentionsBody}}</li>
                {{else}}
                  {{#if this.channelWideMentionDisallowed}}
                    <li>{{i18n
                        "chat.mention_warning.channel_wide_mentions_disallowed"
                      }}</li>
                  {{/if}}
                  {{#if this.hasUnreachableGroupMentions}}
                    <li>{{this.unreachableBody}}</li>
                  {{/if}}
                  {{#if this.hasOverMembersLimitGroupMentions}}
                    <li>{{this.overMembersLimitBody}}</li>
                  {{/if}}
                {{/if}}
              </ul>
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "MvGWhoP2",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-mention-warnings\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-mention-warning__icon\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"triangle-exclamation\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"chat-mention-warning__text\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"chat-mention-warning__header\"],[12],[1,\"\\n            \"],[1,[30,0,[\"warningHeaderText\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"ul\"],[15,0,[30,0,[\"listStyleClass\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasTooManyMentions\"]],[[[1,\"              \"],[10,\"li\"],[12],[1,[30,0,[\"tooManyMentionsBody\"]]],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"channelWideMentionDisallowed\"]],[[[1,\"                \"],[10,\"li\"],[12],[1,[28,[32,1],[\"chat.mention_warning.channel_wide_mentions_disallowed\"],null]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"hasUnreachableGroupMentions\"]],[[[1,\"                \"],[10,\"li\"],[12],[1,[30,0,[\"unreachableBody\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"hasOverMembersLimitGroupMentions\"]],[[[1,\"                \"],[10,\"li\"],[12],[1,[30,0,[\"overMembersLimitBody\"]]],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-mention-warnings.js",
      "scope": () => [_dIcon.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatMentionWarnings;
});